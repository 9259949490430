<template>
  <div class="root">
    <div class="container">
      <div class="row progressbar">
        <div class="step">
            <div class="step-circle"
            :class="{'active': currentStep === 1,
            'vgh-blau-bg': currentStep === 1 && mandant === 1,
            'vgh-blau-bg-sekundaer-color': currentStep !== 1 && mandant === 1,
            'oesa-gruen-bg': currentStep === 1 && mandant === 3,
            'oesa-gruen-bg-sekundaer-color': currentStep !== 1 && mandant === 3,
            'oevo-blau-bg': currentStep === 1 && mandant === 5,
            'oevo-blau-bg-sekundaer-color': currentStep !== 1 && mandant === 5}"
            >1</div>
            <div class="step-lable copy_bold_12">Registrierungsdaten erfassen</div>
        </div>
        <div class="line">
          <hr>
        </div>
        <div class="step">
          <div class="step-circle" :class="{'active': currentStep === 2,
            'vgh-blau-bg': currentStep === 2 && mandant === 1,
            'vgh-blau-bg-sekundaer-color': currentStep !== 2 && mandant === 1,
            'oesa-gruen-bg': currentStep === 2 && mandant === 3,
            'oesa-gruen-bg-sekundaer-color': currentStep !== 2 && mandant === 3,
            'oevo-blau-bg': currentStep === 2 && mandant === 5,
            'oevo-blau-bg-sekundaer-color': currentStep !== 2 && mandant === 5}">2</div>
          <div class="step-lable copy_bold_12">SMS-Code bestätigen</div>
        </div>
        <div class="line">
          <hr>
        </div>
        <div class="step">
          <div class="step-circle" :class="{'active': currentStep === 3,
            'vgh-blau-bg': currentStep === 3 && mandant === 1,
            'vgh-blau-bg-sekundaer-color': currentStep !== 3 && mandant === 1,
            'oesa-gruen-bg': currentStep === 3 && mandant === 3,
            'oesa-gruen-bg-sekundaer-color': currentStep !== 3 && mandant === 3,
            'oevo-blau-bg': currentStep === 3 && mandant === 5,
            'oevo-blau-bg-sekundaer-color': currentStep !== 3 && mandant === 5}">3</div>
          <div class="step-lable copy_bold_12">E-Mail-Adresse bestätigen</div>
        </div>
        <div class="line">
          <hr>
        </div>
        <div class="step">
          <div class="step-circle" :class="{'active': currentStep === 4,
            'vgh-blau-bg': currentStep === 4 && mandant === 1,
            'vgh-blau-bg-sekundaer-color': currentStep !== 4 && mandant === 1,
            'oesa-gruen-bg': currentStep === 4 && mandant === 3,
            'oesa-gruen-bg-sekundaer-color': currentStep !== 4 && mandant === 3,
            'oevo-blau-bg': currentStep === 4 && mandant === 5,
            'oevo-blau-bg-sekundaer-color': currentStep !== 4 && mandant === 5}">4</div>
          <div class="step-lable copy_bold_12">Nect Authentifizierung</div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: 'ProzessSchritte',
  props: ['currentStep', 'mandant'],
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.step-circle.active {
  background-color: #484a4f;
}
.root {
  width: 100%;
}
.step-circle {
  height: 25px;
  width: 25px;
  background-color: #c4d0e4;
  border-radius: 50%;
  color: white;
  display: inline-block;
  text-align: center;
  margin-bottom: 5px;
}
.step-lable {
  text-align: center;
}
.step {
  width: 24%;
  text-align: center;
}
.line {
  width: 17%;
  margin-right: -8%;
  margin-left: -8%;
  margin-top: -4px;
}

/* Tablet View */
@media (max-width: 768px) {
  .step {
    width: 23%;
  }
}

/* Mobile View */
@media (max-width: 576px) {
  .step-lable {
    display: none;
  }
}

</style>
