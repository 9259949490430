<template>
  <!-- eslint-disable max-len -->
  <div id="app">
    <div v-if="!initialesLadenAbgeschlossen || !logoGeladen" id="ladebildschirm"></div>
    <div id="header" class="container">
      <div class="row">
        <div id="logo" class="col-2">
          <router-link to="/">
            <div v-if="mandant === 1" alt="VGH Logo" >
              <div class="vgh-logo-container">
              <img
                @load="onLogoGeladen"
                id="vgh-logo"
                alt="VGH Logo"
                src="https://www.vgh.de/export/sites/vgh/_resources/bilder/header-footer/vgh-AD-logo.svg"
              >
              </div>
            </div>
            <div v-else-if="mandant === 3">
              <div class="logo-masse">
                  <img
                    @load="onLogoGeladen"
                    id="oesa-logo"
                    alt="ÖSA Logo"
                    src="@/assets/Meine_OESA_Logo.svg"
                  >
              </div>
            </div>
            <div v-else-if="mandant === 5" class="logo-container">
              <img
                @load="onLogoGeladen"
                id="oevo-logo"
                alt="Öffentliche Logo"
                src="@/assets/OEVO-Logo.png"
              >
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <hr class="header-border">
    <div class="content container">
      <div id="heading" class="row">
        <div class="col-xl-2 col-1"></div>
        <div class="slogan col-xl-8 col-10">
          <h1 v-if="mandant === 1">Meine VGH</h1>
          <h1 v-else-if="mandant === 3">Meine ÖSA</h1>
          <h1 v-else-if="mandant === 5">Meine Öffentliche</h1>
          <div class="copy_18">
            Ein Klick, alles im Blick - mit dem Kundenportal.</div>
        </div>
        <div class="col-xl-2 col-1"></div>
      </div>
      <router-view @view-geladen="onViewGeladen" />
    </div>
    <div id="footer" class="footer">
<!--      <footer class="text-center text-lg-start text-muted">-->
      <footer class="text-center">
        <!-- Left -->
        <div class="container me-5 d-block copy_16">
          <div class="row">
            <section
              class="footer-section d-flex justify-content-center justify-content-lg-between"
            >
              <a v-if="mandant === 1" href="https://www.vgh.de/content/impressum/" target="_blank" rel=noopener class="me-4 footer-font">
                Impressum
              </a>
              <a v-else-if="mandant === 3" href="https://www.oesa.de/content/unternehmen/die-oesa/impressum/" target="_blank" rel=noopener class="me-4 footer-font">
                Impressum
              </a>
              <a v-else-if="mandant === 5" href="https://www.oeffentlicheoldenburg.de/content/randseiten/impressum/" target="_blank" rel=noopener class="me-4 footer-font">
                Impressum
              </a>
            </section>
            <section
              class="footer-section d-flex justify-content-center justify-content-lg-between"
            >
              <a v-if="mandant === 1" href="https://www.vgh.de/content/datenschutz/" target="_blank" rel=noopener class="me-4 footer-font">
                Datenschutz
              </a>
              <a v-else-if="mandant === 3" href="https://www.oesa.de/content/unternehmen/die-oesa/datenschutz/" target="_blank" rel=noopener class="me-4 footer-font">
                Datenschutz
              </a>
              <a v-else-if="mandant === 5" href="https://www.oeffentlicheoldenburg.de/content/randseiten/datenschutz/" target="_blank" rel=noopener class="me-4 footer-font">
                Datenschutz
              </a>
            </section>
            <section
              class="footer-section d-flex justify-content-center justify-content-lg-between"
            >
              <a v-if="mandant === 1" href="https://www.vgh.de/meine_vgh_nutzungsbedingungen" target="_blank" rel=noopener class="me-4 footer-font">
                Nutzungsbedingungen
              </a>
              <a v-else-if="mandant === 3" href="https://www.oesa.de/meine_oesa_nutzungsbedingungen" target="_blank" rel=noopener class="me-4 footer-font">
                Nutzungsbedingungen
              </a>
              <a v-else-if="mandant === 5" href="https://www.oeffentlicheoldenburg.de/kundenportal_nutzungsbedingungen" target="_blank" rel=noopener class="me-4 footer-font">
                Nutzungsbedingungen
              </a>
            </section>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'App',
  mixins: [mandant],
  data() {
    return {
      initialesLadenAbgeschlossen: false,
      logoGeladen: false,
    };
  },
  methods: {
    onViewGeladen() {
      this.initialesLadenAbgeschlossen = true;
    },
    onLogoGeladen() {
      this.logoGeladen = true;
      console.log('Logo geladen true');
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
/* Global View */
#app {
  font-family: 'Sparkasse_web_Rg', sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  flex-direction: column;
  height: 100%;
}

#heading {
  margin-top: 50px;
  text-align: left;
}

#ladebildschirm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  z-index: 999;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
}

#logo {
  text-align: start;
  padding-top: 10px;
}

#header {
  height: 80px;
  font-family: 'Sparkasse_web_Lt', sans-serif;
  font-size: 16px;
  line-height:24px;
  padding-bottom: 5px;
}

#header a {
  color: #2c3e50;
}

#vgh-logo {
  width: 120px;
  height: auto;
  padding-top: 15px;
}

#oevo-logo {
  width: 100%;
  height: auto;
  padding: 15px 0px;
}

#oesa-logo {
  width: 60%;
  height: auto;
}

.header-border {
  border-color: #EDF0F3;
  width: 100vw;
  border-style: inset;
  margin-bottom: 0;
  margin-top: 0;
}

#footer {
  background: #484A4F;
}

.footer-section {
  padding: 1.5rem;
}

.logo-masse {
  width: 100%;
  height: auto;
}

.logo-container {
  width: 150px;
  height: 60px;
  margin-left: 15px;
}

.vgh-logo-container {
  width: 123px;
  height: 60px;
}

.nav-element a{
  padding: 30px 20px 26px 20px;
}

.main-content {
  text-align: left;
  padding-top: 60px;
  padding-bottom: 1.5rem;
}

.abstand-unten-17 {
  margin-bottom: 17px;
}

@media (max-width: 1199px) and (min-width: 992px){
  .slogan {
    padding-left: 35px;
  }
}

@media (max-width: 992px) {
  .logo-masse {
    width: 60px;
  }

  #oesa-logo {
    width: 100%;
  }
}

@media (max-width: 991px) {
  #heading .col-1 {
    display: none;
  }
}

/* Tablet View */
@media (max-width: 768px) {
  .footer-font {
    font-size: small;
  }

  .footer-section {
    padding: 1rem;
  }
}

/* Mobile View */
@media (max-width: 576px) {
  .main-content {
    padding-top: 30px;
    padding-bottom: 0;
  }
}

</style>
