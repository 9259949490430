import { render, staticRenderFns } from "./Registrierungsdaten.vue?vue&type=template&id=b2706670&scoped=true&"
import script from "./Registrierungsdaten.vue?vue&type=script&lang=js&"
export * from "./Registrierungsdaten.vue?vue&type=script&lang=js&"
import style0 from "./Registrierungsdaten.vue?vue&type=style&index=0&id=b2706670&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2706670",
  null
  
)

export default component.exports