import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

const API_BASE_PATH = 'api/draft';
const AXIOS = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}${API_BASE_PATH}`,
});

export default {
  getMandantengruppenId() {
    return AXIOS.get('/info/mandantengruppenid');
  },
  getWebappPortalUrl() {
    return AXIOS.get('/info/webappportalurl');
  },
  sendeRegistrierungsdaten(registrierungsdaten) {
    return AXIOS.post('/benutzer/verifizierung',
      registrierungsdaten,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  },
  sendeSms(registrierungsdaten) {
    return AXIOS.post('/benutzer/sms/send',
      registrierungsdaten,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  },

  pruefeSmsCode(registrierungsdaten, smsCode) {
    return AXIOS.post('/benutzer/sms/check-code',
      registrierungsdaten,
      {
        headers: {
          smsCode,
          'Content-Type': 'multipart/form-data',
        },
      });
  },

  sendeBestaetigungsEmail(emailAdresse) {
    return AXIOS.post('/benutzer/registrierung/email',
      emailAdresse,
      {
        headers: {
          'Content-Type': 'text/plain',
        },
      });
  },
};
