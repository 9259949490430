import Vue from 'vue';
import Vuex from 'vuex';
import api from '../backend-api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    istMandantGesetzt: false,
    mandant: '',
    smsCode: '',
    registrierungsdaten: {
      vorname: '',
      nachname: '',
      emailAdresse: '',
      vsnr: '',
      handynummer: '',
      nutzungsbedingungen: false,
      einwilligung: false,
      werbeeinwilligung: false,
    },
  },
  mutations: {
    SET_FEHLER(state, fehler) {
      state.fehler = fehler;
    },
    SET_MANDANT(state, mandant) {
      state.mandant = mandant;
      state.istMandantGesetzt = true;
    },
  },
  actions: {
    async ladeMandantengruppenId({ commit }) {
      await api.getMandantengruppenId()
        .then((response) => response.data)
        .then((mandant) => {
          commit('SET_MANDANT', mandant);
        }).catch((error) => {
          commit('SET_FEHLER', true);
          console.log(`Mandant: ${error}`);
        });
    },
  },
  modules: {
  },
});
