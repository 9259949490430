<template>
  <div class="col-xl-12 bg-light textbox-grau">
    <h3> E-Mail-Adresse bestätigen</h3>
    <p class="copy_16">
      Ihnen wurde eine E-Mail mit einem Bestätigungslink an die E-Mail-Adresse
      <span class="copy_bold_16">{{ registrierungsdaten.emailAdresse }}</span> gesendet.
      Bitte klicken Sie auf den Link in der E-Mail,
      um den Registrierungsprozess zu vervollständigen.
    </p>
    <p class="copy_16">
      Sie haben keine E-Mail erhalten? <br>
      Bitte überprüfen Sie Ihre E-Mail-Adresse und senden sich erneut eine Bestätigungsmail.
    </p>
    <input type="button"
           id="email-btn"
           class="button-ivv abstand-unten-17"
           value="E-Mail erneut senden"
           @click="emailSenden"
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/backend-api';

export default {
  name: 'EmailBestaetigung',
  props: ['mandant'],
  computed: {
    ...mapState(['registrierungsdaten']),
  },
  methods: {
    async emailSenden() {
      await api.sendeBestaetigungsEmail(
        this.$store.state.registrierungsdaten.emailAdresse,
      );
    },
  },
};
</script>
