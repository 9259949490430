var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.handynummerFeld)?_c('div',{staticClass:"row row-padding"},[_c('div',{staticClass:"col-12 bg-light"},[_c('label',{staticClass:"aufschrift copy_bold_12",attrs:{"for":"handynummer"}},[_vm._v(" Handynummer *")]),_c('div',[_c('div',{staticClass:"eingabe-form copy_16",class:{
                  'eingabe-fehler': _vm.handynummerFehler,
                  'vgh-eingabe-form': _vm.mandant === 1,
                  'oesa-eingabe-form': _vm.mandant === 3,
                  'oevo-eingabe-form': _vm.mandant === 5,
                  'fehler-oevo': _vm.handynummerFehler && _vm.mandant === 5}},[_c('span',{staticClass:"dropdown dropdown-button",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleVorwahlnummerDropdown.apply(null, arguments)}}},[(_vm.vorwahlnummer === '+49')?_c('span',{staticClass:"fi fi-de dropdown-bild"}):_vm._e(),(_vm.vorwahlnummer === '+31')?_c('span',{staticClass:"fi fi-nl dropdown-bild"}):_vm._e(),_c('em',{staticClass:"fa-solid fa-chevron-down"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.handynummerEingabe),expression:"handynummerEingabe"}],staticClass:"eingabe eingabe-handynummer copy_16",staticStyle:{"border":"none"},attrs:{"maxlength":"15","id":"handynr"},domProps:{"value":(_vm.handynummerEingabe)},on:{"keypress":_vm.nurHandynummerZahlen,"paste":_vm.behandleHandynummerEinfuegen,"input":[function($event){if($event.target.composing)return;_vm.handynummerEingabe=$event.target.value},function($event){_vm.handynummerFehler = false}]}})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVorwahlnummerDropdown),expression:"showVorwahlnummerDropdown"}],staticClass:"dropdown-inhalt"},[_c('li',{staticClass:"dropdown-auswahl copy_12",class:{'vgh-blau-bg-hover dropdown-hover-text': _vm.mandant === 1,
                           'oesa-gruen-bg-hover dropdown-hover-text': _vm.mandant === 3,
                          'oevo-blau-bg-hover dropdown-hover-text': _vm.mandant === 5},on:{"click":function($event){return _vm.vorwahlnummerAuswahl('+49')}}},[_c('span',{staticClass:"fi fi-de dropdown-bild"}),_vm._v("   Deutschland +49 ")]),_c('li',{staticClass:"dropdown-auswahl copy_12",class:{'vgh-blau-bg-hover dropdown-hover-text': _vm.mandant === 1,
                          'oesa-gruen-bg-hover dropdown-hover-text': _vm.mandant === 3,
                          'oevo-blau-bg-hover dropdown-hover-text': _vm.mandant === 5},on:{"click":function($event){return _vm.vorwahlnummerAuswahl('+31')}}},[_c('span',{staticClass:"fi fi-nl dropdown-bild"}),_vm._v("   Niederlande +31 ")])]),(_vm.handynummerFehler)?_c('label',{staticClass:"aufschrift fehler-text copy_bold_12 row row-padding",class:{'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(" Bitte geben Sie eine gültige "+_vm._s(_vm.vorwahlnummer === '+49' ? 'deutsche' : 'niederländische')+" Handynummer ein. ")]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"button-reihe"},[_c('button',{staticClass:"button-ivv grau-dark",on:{"click":_vm.abbrechen}},[_vm._v(" Abbrechen ")]),_c('button',{staticClass:"button-ivv white",class:{'button-vgh': _vm.mandant === 1,
                           'button-oesa': _vm.mandant === 3,
                           'button-oevo': _vm.mandant === 5,
                           'button-disabled': _vm.smsButtonDisabled},attrs:{"disabled":_vm.smsButtonDisabled},on:{"click":_vm.handynummerAendern}},[_vm._v(" SMS senden ")])])])])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"auto bg-light row-padding",attrs:{"id":"sms-bestaetigung"}},[_c('h3',{staticClass:"centered abstand-unten-17"},[_vm._v("SMS-Code bestätigen")]),_c('p',{staticClass:"copy_12 centered"},[_vm._v("Wir haben eine SMS an die angegebene Handynummer "),_c('span',{staticClass:"copy_bold_12 abstand-unten-30 centered",attrs:{"id":"handynummer"}},[_vm._v(" "+_vm._s(_vm.handynummer))]),_vm._v(" versendet")]),_c('p',{staticClass:"copy_bold_12 abstand-unten-30 centered",on:{"click":function($event){_vm.handynummerFeld=true}}},[_c('pfeil-button',{attrs:{"mandant":_vm.mandant,"button-text":"Handynummer ändern"}})],1),_c('div',{staticClass:"sms-eingabe"},[_c('p',{staticClass:"copy_12 centered"},[_vm._v("Bitte geben Sie den SMS-Code hier ein")]),_c('div',{staticClass:"centered"},[_c('div',{staticClass:"sms-code-eingabe copy_bold_16"},[_vm._v("SMS-Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.smsCode),expression:"smsCode"}],staticClass:"eingabe copy_16 sms-code-eingabe",class:{
                  'eingabe-fehler': _vm.smsCodeError,
                  'vgh-eingabe': _vm.mandant === 1,
                  'oesa-eingabe': _vm.mandant === 3,
                  'oevo-eingabe': _vm.mandant === 5,
                  'fehler-oevo': _vm.smsCodeError && _vm.mandant === 5},attrs:{"maxlength":"6","id":"sms-code","required":""},domProps:{"value":(_vm.smsCode)},on:{"keypress":_vm.nurZahlen,"paste":_vm.behandleEinfuegen,"click":function($event){_vm.smsCodeError = false},"input":function($event){if($event.target.composing)return;_vm.smsCode=$event.target.value}}}),(_vm.smsCodeError)?_c('label',{staticClass:"aufschrift fehler-text copy_bold_12",class:{'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(" "+_vm._s(_vm.smsCodeErrorText)+" ")]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"button-reihe"},[_c('button',{staticClass:"button-ivv grau-dark",class:{'button-disabled': _vm.smsButtonDisabled},attrs:{"disabled":_vm.smsButtonDisabled},on:{"click":_vm.fordereSMS}},[_vm._v(" SMS erneut versenden ")]),_c('button',{staticClass:"button-ivv white",class:{'button-vgh': _vm.mandant === 1,
                       'button-oesa': _vm.mandant === 3,
                       'button-oevo': _vm.mandant === 5,
                       'button-disabled': _vm.weiterButtonDisabled},attrs:{"disabled":_vm.weiterButtonDisabled,"id":"weiter-btn"},on:{"click":_vm.pruefeSmsCode}},[_vm._v(" Weiter ")])])]),(_vm.allgemeinerError)?_c('label',{staticClass:"aufschrift fehler-text copy_bold_12",class:{'fehler-text-oevo': _vm.mandant === 5},attrs:{"id":"errorText"}},[_vm._v(" "+_vm._s(_vm.spezifischerErrorText)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.allgemeinerErrorText)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.supportEmailAdresse)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.supportHandynummer)+" ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }