<template>
  <a id="button" class="copy_12 pfeil-btn text-link" @click="zumLink"
     :class="{'vgh-blau-color': mandant === 1,
      'vgh-blau-text-decoration-hover': mandant === 1,
      'oesa-gruen-color': mandant === 3,
      'oesa-gruen-text-decoration-hover': mandant === 3,
      'oevo-blau-color': mandant === 5,
      'oevo-blau-text-decoration-hover': mandant === 5}">
    <em class="fa-solid fa-chevron-right pfeil"></em>
    {{buttonText}}
  </a>
</template>

<script>

export default {
  name: 'PfeilButton',
  props: ['button-text', 'path', 'param', 'mandant'],

  methods: {
    zumLink() {
      if (this.path) {
        if (this.param) {
          this.$router.push({ path: `/${this.path}/${this.param}` });
        } else {
          this.$router.push(this.path);
        }
      }
    },
  },
};
</script>

<style scoped>
.pfeil-btn {
  color: #484a4f;
  background:none;
  padding-bottom: 0;
  padding-left: 0;
  cursor: pointer;
}

.pfeil-btn:hover {
  text-decoration: underline;
  text-decoration-color: #484a4f;
  color: #484a4f;
}

.pfeil {
  font-size: 12px;
  margin-left: 8px;
  margin-right: 1px;
}
</style>
