<template>
  <div>
    <div v-if="handynummerFeld" class="row row-padding">
      <div class="col-12 bg-light">
        <label class="aufschrift copy_bold_12" for="handynummer">
          Handynummer *</label>
        <div>
          <div class="eingabe-form copy_16"
                :class="{
                    'eingabe-fehler': handynummerFehler,
                    'vgh-eingabe-form': mandant === 1,
                    'oesa-eingabe-form': mandant === 3,
                    'oevo-eingabe-form': mandant === 5,
                    'fehler-oevo': handynummerFehler && mandant === 5}">
            <span class="dropdown dropdown-button"
                    @click.stop.prevent="toggleVorwahlnummerDropdown">
              <span v-if="vorwahlnummer === '+49'" class="fi fi-de dropdown-bild"></span>
              <span v-if="vorwahlnummer === '+31'" class="fi fi-nl dropdown-bild"></span>
              <em class="fa-solid fa-chevron-down"/>
            </span>
            <input class="eingabe eingabe-handynummer copy_16"
                   style="border:none"
                   v-model="handynummerEingabe"
                   maxlength="15"
                   id="handynr"
                   @keypress="nurHandynummerZahlen"
                   @paste="behandleHandynummerEinfuegen"
                   @input="handynummerFehler = false">
          </div>
          <ul v-show="showVorwahlnummerDropdown" class="dropdown-inhalt">
            <li
              class="dropdown-auswahl copy_12"
              :class="{'vgh-blau-bg-hover dropdown-hover-text': mandant === 1,
                             'oesa-gruen-bg-hover dropdown-hover-text': mandant === 3,
                            'oevo-blau-bg-hover dropdown-hover-text': mandant === 5}"
              @click="vorwahlnummerAuswahl('+49')">
              <span class="fi fi-de dropdown-bild"></span> &nbsp; Deutschland +49
            </li>
            <li
              class="dropdown-auswahl copy_12"
              :class="{'vgh-blau-bg-hover dropdown-hover-text': mandant === 1,
                            'oesa-gruen-bg-hover dropdown-hover-text': mandant === 3,
                            'oevo-blau-bg-hover dropdown-hover-text': mandant === 5}"
              @click="vorwahlnummerAuswahl('+31')">
              <span class="fi fi-nl dropdown-bild"></span> &nbsp; Niederlande +31
            </li>
          </ul>
          <label v-if="handynummerFehler"
                 class="aufschrift fehler-text copy_bold_12 row row-padding"
                 :class="{'fehler-text-oevo': mandant === 5}">
            Bitte geben Sie eine gültige
            {{ vorwahlnummer === '+49' ? 'deutsche' : 'niederländische' }} Handynummer ein.
          </label>
        </div>
        <div class="row">
          <div class="button-reihe">
            <button
              @click="abbrechen"
              class="button-ivv grau-dark">
              Abbrechen
            </button>
            <button class="button-ivv white"
                    :class="{'button-vgh': mandant === 1,
                             'button-oesa': mandant === 3,
                             'button-oevo': mandant === 5,
                             'button-disabled': smsButtonDisabled}"
                    :disabled="smsButtonDisabled"
                    @click="handynummerAendern">
              SMS senden
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div id="sms-bestaetigung" class="auto bg-light row-padding">
        <h3 class="centered abstand-unten-17">SMS-Code bestätigen</h3>
        <p class="copy_12 centered">Wir haben eine SMS an die angegebene Handynummer
          <span id="handynummer" class="copy_bold_12 abstand-unten-30 centered">
            {{ handynummer }}</span> versendet</p>
        <p @click="handynummerFeld=true" class="copy_bold_12 abstand-unten-30 centered">
          <pfeil-button :mandant="mandant" button-text="Handynummer ändern">
          </pfeil-button>
        </p>
        <div class="sms-eingabe">
          <p class="copy_12 centered">Bitte geben Sie den SMS-Code hier ein</p>
          <div class="centered">
            <div class="sms-code-eingabe copy_bold_16">SMS-Code</div>
            <input class="eingabe copy_16 sms-code-eingabe"
                   :class="{
                    'eingabe-fehler': smsCodeError,
                    'vgh-eingabe': mandant === 1,
                    'oesa-eingabe': mandant === 3,
                    'oevo-eingabe': mandant === 5,
                    'fehler-oevo': smsCodeError && mandant === 5}"
                   @keypress="nurZahlen"
                   @paste="behandleEinfuegen"
                   @click="smsCodeError = false"
                   v-model="smsCode"
                   maxlength="6"
                   id="sms-code"
                   required>
            <label v-if="smsCodeError"
                   class="aufschrift fehler-text copy_bold_12"
                   :class="{'fehler-text-oevo': mandant === 5}">
              {{ smsCodeErrorText }}
            </label>
          </div>
          <div class="row">
            <div class="button-reihe">
              <button
                class="button-ivv grau-dark"
                :class="{'button-disabled': smsButtonDisabled}"
                @click="fordereSMS"
                :disabled="smsButtonDisabled">
                SMS erneut versenden
              </button>
              <button
                @click="pruefeSmsCode"
                :disabled="weiterButtonDisabled"
                id="weiter-btn"
                class="button-ivv white"
                :class="{'button-vgh': mandant === 1,
                         'button-oesa': mandant === 3,
                         'button-oevo': mandant === 5,
                         'button-disabled': weiterButtonDisabled}">
                Weiter
              </button>
            </div>
          </div>
          <label id="errorText" v-if="allgemeinerError"
                 class="aufschrift fehler-text copy_bold_12"
                 :class="{'fehler-text-oevo': mandant === 5}">
            {{ spezifischerErrorText }}
            <br>
            {{ allgemeinerErrorText }}
            <br>
            {{ supportEmailAdresse }}
            <br>
            {{ supportHandynummer }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PfeilButton from '@/components/PfeilButton.vue';
import api from '@/backend-api';
import sicherheitsabfrage from '@/sicherheitsabfrage';

export default {
  name: 'SmsBestaetigung',
  mixins: [sicherheitsabfrage],
  data() {
    return {
      spezifischerErrorText: '',
      allgemeinerErrorText: 'Bitte wenden Sie sich an das Supportteam.',
      allgemeinerError: false,
      weiterButtonDisabled: false,
      smsButtonDisabled: false,
      smsCode: '',
      smsCodeError: false,
      smsCodeErrorText: '',
      supportDomain: '',
      supportEmailAdresse: '',
      supportHandynummer: '',
      handynummerEingabe: '',
      showVorwahlnummerDropdown: false,
      vorwahlnummer: '+49',
      handynummerFehler: false,
      handynummerFeld: false,
    };
  },
  props: [
    'mandant',
  ],
  computed: {
    handynummer() {
      return this.$store.state.registrierungsdaten.handynummer;
    },
  },
  components: {
    PfeilButton,
  },
  methods: {
    abbrechen() {
      this.handynummerFehler = false;
      this.handynummerFeld = false;
      this.smsCode = '';
      this.smsCodeError = false;
    },
    behandleEinfuegen(value) {
      value.preventDefault();
      let eingefuegtes = value.clipboardData.getData('text/plain');
      eingefuegtes = eingefuegtes.replace(/\D/g, '');
      this.smsCode = eingefuegtes;
    },
    behandleHandynummerEinfuegen(value) {
      value.preventDefault();
      let eingefuegtes = value.clipboardData.getData('text/plain');
      eingefuegtes = eingefuegtes.replace(/^[^1-9]+|\D/g, '');
      this.handynummerEingabe = eingefuegtes;
    },
    toggleVorwahlnummerDropdown() {
      this.showVorwahlnummerDropdown = !this.showVorwahlnummerDropdown;
    },
    vorwahlnummerAuswahl(vorwahlnummer) {
      this.vorwahlnummer = vorwahlnummer;
      this.toggleVorwahlnummerDropdown();
    },
    eingegeben() {
      return !!this.smsCode;
    },
    async fordereSMS() {
      try {
        const formData = new FormData();
        formData.append('vorname', this.$store.state.registrierungsdaten.vorname);
        formData.append('nachname', this.$store.state.registrierungsdaten.nachname);
        formData.append('email', this.$store.state.registrierungsdaten.emailAdresse);
        formData.append('vsnr', this.$store.state.registrierungsdaten.vsnr);
        formData.append('handynummer', this.$store.state.registrierungsdaten.handynummer);
        formData.append('nutzungsbedingungen', this.$store.state.registrierungsdaten.nutzungsbedingung);
        formData.append('einwilligung', this.$store.state.registrierungsdaten.einwilligung);
        formData.append('werbeeinwilligung', this.$store.state.registrierungsdaten.werbeeinwilligung);
        this.$store.state.smsCode = (await api.sendeSms(formData, this.smsCode)).data;
      } catch (e) {
        console.log(e);
        if (e.response.status === 429) {
          this.smsButtonDisabled = true;
          this.allgemeinerError = true;
          this.spezifischerErrorText = 'Es wurden zu viele SMS-Codes angefordert,'
            + ' daher kann Ihre Registrierung nicht fortgesetzt werden.';
        }
      }
    },
    checkeEingabeFehler() {
      return !this.smsCodeError;
    },
    handynummerAendern() {
      this.validateHandynummer();
      if (!this.handynummerFehler) {
        this.$store.state.registrierungsdaten.handynummer = `${this.vorwahlnummer}${this.handynummerEingabe}`;
        this.fordereSMS();
        this.handynummerFeld = false;
      }
    },
    async naechsterSchritt() {
      this.$emit('naechster-schritt', 3);
    },
    nurZahlen(eingabe) {
      const regEx = new RegExp(/^[0-9]*$/);
      if (!regEx.test(eingabe.key)) {
        eingabe.preventDefault();
      }
    },
    nurHandynummerZahlen(eingabe) {
      let regEx = new RegExp(/^[0-9]*$/);
      if (this.handynummerEingabe.length === 0) {
        regEx = new RegExp(/^[1-9]*$/);
      }
      if (!regEx.test(eingabe.key)) {
        eingabe.preventDefault();
      }
    },
    async pruefeSmsCode() {
      if (!this.smsCode) {
        this.smsCodeError = true;
        this.smsCodeErrorText = 'Bitte geben Sie den SMS-Code ein.';
      } else if (this.smsCode.length < 6) {
        this.smsCodeError = true;
        this.smsCodeErrorText = 'Der Code muss 6 Ziffern beeinhalten.';
      } else {
        try {
          this.weiterButtonDisabled = true;
          const formData = new FormData();
          formData.append('vorname', this.$store.state.registrierungsdaten.vorname);
          formData.append('nachname', this.$store.state.registrierungsdaten.nachname);
          formData.append('email', this.$store.state.registrierungsdaten.emailAdresse);
          formData.append('vsnr', this.$store.state.registrierungsdaten.vsnr);
          formData.append('handynummer', this.$store.state.registrierungsdaten.handynummer);
          formData.append('nutzungsbedingungen', this.$store.state.registrierungsdaten.nutzungsbedingung);
          formData.append('einwilligung', this.$store.state.registrierungsdaten.einwilligung);
          formData.append('werbeeinwilligung', this.$store.state.registrierungsdaten.werbeeinwilligung);
          // eslint-disable-next-line max-len
          const smsCodeValid = (await (api.pruefeSmsCode(formData, this.smsCode))).data;
          if (!smsCodeValid) {
            this.smsCodeError = true;
            this.weiterButtonDisabled = false;
            this.smsCodeErrorText = 'Der eingegebene Code ist nicht korrekt.';
            this.smsCodePruefVersuche += 1;
          } else {
            this.naechsterSchritt();
          }
        } catch (e) {
          console.log(e);
          if (e.response.status === 429) {
            this.smsCodeError = true;
            this.allgemeinerError = true;
            this.weiterButtonDisabled = true;
            this.smsButtonDisabled = true;
            this.setSupportDaten();
            this.spezifischerErrorText = 'Es gab zu viele Fehlversuche,'
              + ' daher kann Ihre Registrierung nicht fortgesetzt werden.';
          }
        }
      }
    },
    setSupportDaten() {
      if (this.$store.state.mandant === 1) {
        this.supportDomain = 'vgh';
        this.supportHandynummer = '0800 - 1750900';
      } else if (this.$store.state.mandant === 3) {
        this.supportDomain = 'oesa';
        this.supportHandynummer = '0391 - 7367 655';
      } else {
        this.supportDomain = 'oevo';
        this.supportHandynummer = '0441 - 2228 120';
      }
      this.supportEmailAdresse = `E-Mail-Adresse: kundenportal@${this.supportDomain}.de`;
      this.supportHandynummer = `Kostenlose Rufnummer ${this.supportHandynummer}`;
    },
    validateHandynummer() {
      const regEx = new RegExp(/^[1-9][0-9]{0,14}$/);
      if (!this.handynummerEingabe || !regEx.test(this.handynummerEingabe)) {
        this.handynummerFehler = true;
      } else {
        this.handynummerFehler = false;
        this.$store.state.registrierungsdaten.handynummer = `${this.vorwahlnummer}${this.handynummerEingabe}`;
      }
    },
  },
};
</script>

<style scoped>
#handynummer {
  white-space: nowrap;
}

#sms-bestaetigung {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 17px;
  padding-bottom: 17px;
}

.button-reihe {
  margin: 20px 0 15px 15px;
}

.button-disabled, .button-disabled:hover {
  opacity: 0.3;
  box-shadow: none;
  cursor: default;
}

.grau-dark {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0, rgba(0, 0, 0, 0.05));
  transition-property: box-shadow, background-color;
  margin-right: 15px;
}

.sms-code-eingabe {
  display: inline-block;
  margin-right: 20px;
  width: 100px;
}

.vgh-eingabe-form:focus-within {
  border: 1px solid #0558f0;
}

.oevo-eingabe-form:focus-within {
  border: 1px solid #004494;
}

.oesa-eingabe-form:focus-within {
  border: 1px solid #78bfa4 !important;
}

/* Mobile View */
@media (max-width: 576px) {
  .centered {
    text-align: center;
  }
}
</style>
