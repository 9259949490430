<template>
  <div>
    <div class="container main-content">
      <div class="row abstand-unten-40">
      </div>
      <div class="row">
        <div class="col-12">
          <bestaetigung v-if="registrierungErfolgreich===true" :success="true">
            <p>
              Ihre Registrierung war erfolgreich.
              Melden Sie sich direkt an und profitieren Sie von unseren Services im
              <a class="webapp-link" :href="webappURL"> Kundenportal</a>.
            </p>
          </bestaetigung>
          <bestaetigung v-if="registrierungErfolgreich===false" :success="false">
            <p>
              Ihre Registrierung ist leider fehlgeschlagen.
              Bitte wenden Sie sich an Ihre Vertretung, um die Registrierung dort abzuschließen.
            </p>
          </bestaetigung>
        </div>
        <div class="col-12">
          <input v-if="registrierungErfolgreich===true"
                 type="button"
                 id="portal-btn"
                 class="button-ivv abstand-unten-17 white"
                 :class="{'button-vgh': mandant === 1,
                    'button-oesa': mandant === 3,
                    'button-oevo': mandant === 5}"
                 value="Zur Anmeldung"
                 @click="openPortal"
          >
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Bestaetigung from '@/components/Bestaetigung.vue';
import api from '@/backend-api';
import mandant from '@/mandant';

export default {
  name: 'Zusammenfassung',
  mixins: [mandant],
  data() {
    return {
      webappURL: '',
      registrierungErfolgreich: true,
    };
  },
  components: {
    Bestaetigung,
  },
  methods: {
    openPortal() {
      window.location.href = this.webappURL;
    },
  },
  mounted() {
    this.$emit('view-geladen');
  },
  async beforeMount() {
    await api.getWebappPortalUrl()
      .then((response) => response.data)
      .then((url) => {
        this.webappURL = url;
      });
  },
};
</script>

<style scoped>
.webapp-link {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.webapp-link:hover {
  text-decoration: none;
}
</style>
