var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"root"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row progressbar"},[_c('div',{staticClass:"step"},[_c('div',{staticClass:"step-circle",class:{'active': _vm.currentStep === 1,
          'vgh-blau-bg': _vm.currentStep === 1 && _vm.mandant === 1,
          'vgh-blau-bg-sekundaer-color': _vm.currentStep !== 1 && _vm.mandant === 1,
          'oesa-gruen-bg': _vm.currentStep === 1 && _vm.mandant === 3,
          'oesa-gruen-bg-sekundaer-color': _vm.currentStep !== 1 && _vm.mandant === 3,
          'oevo-blau-bg': _vm.currentStep === 1 && _vm.mandant === 5,
          'oevo-blau-bg-sekundaer-color': _vm.currentStep !== 1 && _vm.mandant === 5}},[_vm._v("1")]),_c('div',{staticClass:"step-lable copy_bold_12"},[_vm._v("Registrierungsdaten erfassen")])]),_vm._m(0),_c('div',{staticClass:"step"},[_c('div',{staticClass:"step-circle",class:{'active': _vm.currentStep === 2,
          'vgh-blau-bg': _vm.currentStep === 2 && _vm.mandant === 1,
          'vgh-blau-bg-sekundaer-color': _vm.currentStep !== 2 && _vm.mandant === 1,
          'oesa-gruen-bg': _vm.currentStep === 2 && _vm.mandant === 3,
          'oesa-gruen-bg-sekundaer-color': _vm.currentStep !== 2 && _vm.mandant === 3,
          'oevo-blau-bg': _vm.currentStep === 2 && _vm.mandant === 5,
          'oevo-blau-bg-sekundaer-color': _vm.currentStep !== 2 && _vm.mandant === 5}},[_vm._v("2")]),_c('div',{staticClass:"step-lable copy_bold_12"},[_vm._v("SMS-Code bestätigen")])]),_vm._m(1),_c('div',{staticClass:"step"},[_c('div',{staticClass:"step-circle",class:{'active': _vm.currentStep === 3,
          'vgh-blau-bg': _vm.currentStep === 3 && _vm.mandant === 1,
          'vgh-blau-bg-sekundaer-color': _vm.currentStep !== 3 && _vm.mandant === 1,
          'oesa-gruen-bg': _vm.currentStep === 3 && _vm.mandant === 3,
          'oesa-gruen-bg-sekundaer-color': _vm.currentStep !== 3 && _vm.mandant === 3,
          'oevo-blau-bg': _vm.currentStep === 3 && _vm.mandant === 5,
          'oevo-blau-bg-sekundaer-color': _vm.currentStep !== 3 && _vm.mandant === 5}},[_vm._v("3")]),_c('div',{staticClass:"step-lable copy_bold_12"},[_vm._v("E-Mail-Adresse bestätigen")])]),_vm._m(2),_c('div',{staticClass:"step"},[_c('div',{staticClass:"step-circle",class:{'active': _vm.currentStep === 4,
          'vgh-blau-bg': _vm.currentStep === 4 && _vm.mandant === 1,
          'vgh-blau-bg-sekundaer-color': _vm.currentStep !== 4 && _vm.mandant === 1,
          'oesa-gruen-bg': _vm.currentStep === 4 && _vm.mandant === 3,
          'oesa-gruen-bg-sekundaer-color': _vm.currentStep !== 4 && _vm.mandant === 3,
          'oevo-blau-bg': _vm.currentStep === 4 && _vm.mandant === 5,
          'oevo-blau-bg-sekundaer-color': _vm.currentStep !== 4 && _vm.mandant === 5}},[_vm._v("4")]),_c('div',{staticClass:"step-lable copy_bold_12"},[_vm._v("Nect Authentifizierung")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('hr')])
}]

export { render, staticRenderFns }