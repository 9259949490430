<template>
  <div>
    <div class="container main-content">
      <div class="row abstand-unten-40">
        <div class="col-12">
          <prozess-schritte :mandant="mandant" :current-step="prozessSchritt"></prozess-schritte>
        </div>
      </div>
      <div v-if="prozessSchritt === 1">
        <registrierungsdaten
          :mandant="mandant"
          @naechster-schritt="setProzessSchritt">
        </registrierungsdaten>
      </div>
      <div v-else-if="prozessSchritt === 2">
        <sms-bestaetigung
          :mandant="mandant"
          @naechster-schritt="setProzessSchritt">
        </sms-bestaetigung>
      </div>
      <div v-else-if="prozessSchritt === 3" class="row abstand-unten-17">
        <email-bestaetigung></email-bestaetigung>
      </div>
    </div>
  </div>

</template>

<script>
import SmsBestaetigung from '@/components/SmsBestaetigung.vue';
import Registrierungsdaten from '@/components/Registrierungsdaten.vue';
import ProzessSchritte from '@/components/ProzessSchritte.vue';
import EmailBestaetigung from '@/components/EmailBestaetigung.vue';
import mandant from '@/mandant';
import sicherheitsabfrage from '@/sicherheitsabfrage';
// eslint-disable-next-line import/no-absolute-path,import/no-unresolved
import '/node_modules/flag-icons/css/flag-icons.min.css';

export default {
  name: 'Registrierung',
  mixins: [sicherheitsabfrage, mandant],
  data() {
    return {
      prozessSchritt: 1,
    };
  },
  methods: {
    setProzessSchritt(prozessschritt) {
      this.prozessSchritt = prozessschritt;
    },
  },
  components: {
    SmsBestaetigung,
    ProzessSchritte,
    Registrierungsdaten,
    EmailBestaetigung,
  },
  mounted() {
    this.$emit('view-geladen');
  },
};
</script>

<style>
.main-content {
  max-width: 730px;
  min-height: 563px;
  padding-left: 0;
  padding-right: 0;
}

#aufschrift-nachweis {
  margin-top: 0;
}

#close-btn {
  position: absolute;
  top: -13px;
  right: 12px;
  z-index: 1;
  padding: 15px;
  margin: -15px;
}

.textbox-grau {
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 17px;
}

.abstand-unten-17 {
  margin-bottom: 17px;
}

.abstand-unten-30 {
  margin-bottom: 30px;
}

.abstand-unten-40 {
  margin-bottom: 40px;
}

.aufschrift {
  margin-bottom: 6px;
  margin-top: 5px;
}

.eingabe {
  background: #FFFFFF;
  border: 1px solid rgba(203, 207, 211, 0.5);
  box-sizing: border-box;
  display: block;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  padding-top: 1px;
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: middle;
}

.eingabe:focus {
  outline: none !important;
  border: 1px solid #484a4f;
}

.vgh-eingabe:focus {
  border: 1px solid #0558f0;
}

.oevo-eingabe:focus {
  border: 1px solid #004494;
}

.oesa-eingabe:focus {
  border: 1px solid #78bfa4 !important;
}

.eingabe::placeholder {
  color: #babfc4;
}

.eingabe-handynummer {
  background: transparent;
  display: inline-block;
  height: 40px;
  width: 200px;
  padding-top: 1px;
  padding-right: 8px;
  padding-left: 0;
}

.eingabe-fehler {
  margin-right: -2px;
  border: 1px solid #E30613 !important;
}

.eingabe-fehler:focus {
  outline: none !important;
}

.dropdown-button {
  text-align: center;
  padding-top: 8px;
}

.dropdown {
  background: #FFFFFF;
  height: 40px;
  width: 50px;
  cursor: pointer;
}

.eingabe-form {
  background: #FFFFFF;
  border: 1px solid rgba(203, 207, 211, 0.5);
  border-radius: 2px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 260px;
  -webkit-box-shadow: 0 0 0 1000px white inset;
  vertical-align: middle;
}

.dropdown-inhalt {
  background-color: #f1f1f1;
  box-shadow: 2px 2px 8px rgba(53, 53, 53, 0.25);
  display: block;
  list-style-type: none;
  padding-left: 0;
  position: absolute;
  z-index: 1;
}

.dropdown-auswahl {
  cursor: pointer;
  height: 40px;
  width: 140px;
  text-align: left;
  padding-top: 10px;
  padding-left: 5px;
  border-radius: 2px;
  color: black;
}

.fa-chevron-down {
  font-size: 10px;
  padding-left: 3px;
  padding-bottom: 3px;
}

.dropdown-bild {
  height: 15px;
  width: 25px;
  margin-left: 3px;
}

.dropdown-hover-text:hover {
  color:white !important;
}

#handynummer-hinweis {
  padding: 0;
}

.hilfe-icon {
  display: inline-block;
  background-color: #484a4f;
  color: #FFFFFF;
  border-radius: 0;
  text-align: center;
  height: 20px;
  width: 20px;
  position: absolute;
  font-size: 14px;
  margin-top: 4px;
  margin-left: 7px;
  cursor: pointer;
}

.hilfe-icon-voraussetzungen {
  display: inline-block;
  background-color: #484a4f;
  color: #FFFFFF;
  border-radius: 0;
  text-align: center;
  height: 20px;
  width: 20px;
  position: absolute;
  font-size: 14px;
  margin-top: 4px;
  margin-left: 7px;
  cursor: pointer;
}

.hilfe-text {
  width: 450px;
  background-color: #EDF0F3;
  color: #6B7171;
  text-align: left;
  border-radius: 2px;
  padding: 20px 15px;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 22px;
  z-index: 1;
}

.hinweis-text-span {
  padding-inline-end: 6px;
}

.row-padding {
  padding: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="checkbox"].eingabe-fehler + label::before {
  border-color: #E30613;
}

/* Mobile View */
@media (max-width: 576px) {
  #handynummer-hinweis {
    width: 100%;
  }

  #vorraussetzung-aufschrift {
    margin-right: 27px;
  }

  .hilfe-text {
    display: inline-block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 730px;
    width: 80%;
    margin: auto;
    height: fit-content;
  }

  .hilfe-icon-voraussetzungen {
    display: inline-block;
    background-color: #484a4f;
    color: #FFFFFF;
    border-radius: 0;
    text-align: center;
    height: 20px;
    width: 20px;
    position: absolute;
    font-size: 14px;
    margin-top: 4px;
    margin-left: -20px;
    cursor: pointer;
  }
  .dropdown-button {
    padding-left: 2px;
  }
}
</style>
