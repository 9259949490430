export default {
  beforeMount() {
    // Behandelt das Verhalten bei Aktualisierung der Seite, falls Daten eingegeben wurden
    window.addEventListener('beforeunload', this.behandleAktualisierung);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.behandleAktualisierung);
    });
  },

  methods: {
    behandleAktualisierung(event) {
      if (this.eingegeben()) {
        event.preventDefault();
        /* eslint no-param-reassign: "error" */
        event.returnValue = '';
      }
    },
  },
};
