export default {
  data() {
    return {
      mandant: '',
    };
  },
  async beforeMount() {
    if (!this.$store.state.istMandantGesetzt) {
      await this.$store.dispatch('ladeMandantengruppenId');
      this.mandant = this.$store.state.mandant;
    } else {
      this.mandant = this.$store.state.mandant;
    }

    const favicon = document.getElementById('favicon');

    favicon.setAttribute('href', `/${{ 1: 'vgh', 3: 'oesa', 5: 'oevo' }[this.mandant]}.ico`);
    if (this.mandant === 1) {
      document.title = 'Registrierung bei Meine VGH';
    } else if (this.mandant === 3) {
      document.title = 'Registrierung bei Meine ÖSA';
    } else if (this.mandant === 5) {
      document.title = 'Registrierung bei Meine Öffentliche';
    }

    const icon32 = document.getElementById('icon32');
    icon32.setAttribute('href', `icons/${{ 1: 'vgh', 3: 'oesa', 5: 'oevo' }[this.mandant]}-favicon-32.png`);

    const icon128 = document.getElementById('icon128');
    icon128.setAttribute('href', `icons/${{ 1: 'vgh', 3: 'oesa', 5: 'oevo' }[this.mandant]}-favicon-128.png`);

    const icon180 = document.getElementById('icon180');
    icon180.setAttribute('href', `icons/${{ 1: 'vgh', 3: 'oesa', 5: 'oevo' }[this.mandant]}-favicon-180.png`);

    const icon192 = document.getElementById('icon192');
    icon192.setAttribute('href', `icons/${{ 1: 'vgh', 3: 'oesa', 5: 'oevo' }[this.mandant]}-favicon-192.png`);

    const icon512 = document.getElementById('icon512');
    icon512.setAttribute('href', `icons/${{ 1: 'vgh', 3: 'oesa', 5: 'oevo' }[this.mandant]}-favicon-512.png`);

    const iconAndroid32 = document.getElementById('iconAndroid32');
    iconAndroid32.setAttribute('href', `icons/${{ 1: 'vgh', 3: 'oesa', 5: 'oevo' }[this.mandant]}-favicon-32.png`);

    const iconAndroid128 = document.getElementById('iconAndroid128');
    iconAndroid128.setAttribute('href', `icons/${{ 1: 'vgh', 3: 'oesa', 5: 'oevo' }[this.mandant]}-favicon-128.png`);

    const iconAndroid180 = document.getElementById('iconAndroid180');
    iconAndroid180.setAttribute('href', `icons/${{ 1: 'vgh', 3: 'oesa', 5: 'oevo' }[this.mandant]}-favicon-180.png`);

    const iconAndroid192 = document.getElementById('iconAndroid192');
    iconAndroid192.setAttribute('href', `icons/${{ 1: 'vgh', 3: 'oesa', 5: 'oevo' }[this.mandant]}-favicon-192.png`);

    const iconAndroid512 = document.getElementById('iconAndroid512');
    iconAndroid512.setAttribute('href', `icons/${{ 1: 'vgh', 3: 'oesa', 5: 'oevo' }[this.mandant]}-favicon-512.png`);
  },
};
