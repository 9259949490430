<template>
  <div class="card col-12 bestaetigung"
       :class="{'vgh-blau-bg': mandant === 1,
       'oesa-gruen-bg': mandant === 3,
       'oevo-blau-bg': mandant === 5}">
    <div class="card-body">
      <em v-if="success" class="fa-solid fa-check-circle"></em>
      <em v-else-if="!success" class="fa-solid fa-exclamation-triangle"></em>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'Bestaetigung',
  mixins: [mandant],
  props: ['success'],
};
</script>

<style scoped>
.bestaetigung {
  background-color: #484a4f;
  border-radius: 2px;
  color: #FFFFFF;
  font-family: Sparkasse_web_Lt, sans-serif;
  font-size: 18px;
  min-height: 122px;
  margin-bottom: 60px;
  text-align: center;
  width: 100%;
}

.card {
  height: auto;
  margin-left: 0;
  border-color: #EDF0F3;
  padding: 0;
}

.fa-check-circle, .fa-exclamation-triangle {
  font-size: 48px;
  margin-bottom: 10px;
}

@media (max-width: 576px) {
  .bestaetigung {
    margin-bottom: 30px;
    min-height: 146px;
  }
}
</style>
