import Vue from 'vue';
import VueRouter from 'vue-router';
import Registrierung from '@/views/Registrierung.vue';
import Zusammenfassung from '@/views/Zusammenfassung.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Registrierung',
    component: Registrierung,
  },
  {
    path: '/zusammenfassung',
    name: 'Zusammenfassung',
    component: Zusammenfassung,
  },
];

const router = new VueRouter({
  mode: 'hash',
  routes,
});

export default router;
